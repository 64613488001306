import React from "react"
import { graphql } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {

  render() {

    const about = this.props.data;
    const intl = this.props.pageContext.intl;

    return (

      <Layout>
        <SEO lang={intl.language} title={intl.messages.aboutUsSeoTitle} description={intl.messages.aboutUsSeoDescription} />

        <div className="about-us">
          <div className="container reverse">
            <div className="about-us-col-img">
              <div className="about-us-img">
                <Img fluid={about.about_us_person_2.childImageSharp.fluid} alt={intl.messages.aboutUsPersonName1} />
                <p><FormattedMessage id="aboutUsPersonName1" /></p>
              </div>
            </div>
            <div className="about-us-col-text">
              <div className="about-us-text" dangerouslySetInnerHTML={{ __html: intl.messages.aboutUsText1}} />
            </div>
          </div>
          <div className="container">
            <div className="about-us-col-img">
              <div className="about-us-img">
                <Img fluid={about.about_us_person_1.childImageSharp.fluid} alt={intl.messages.aboutUsPersonName2} />
                <p><FormattedMessage id="aboutUsPersonName2" /></p>
              </div>
            </div>
            <div className="about-us-col-text">
              <div className="about-us-text" dangerouslySetInnerHTML={{ __html: intl.messages.aboutUsText2}} />
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default injectIntl(AboutPage)

export const pageQuery = graphql`
  query {
    about_us_person_1: file(relativePath: { eq: "about-us/olya-1.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    about_us_person_2: file(relativePath: { eq: "about-us/olya-2.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`